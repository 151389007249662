<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">人行门禁管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/ownerInfo">业主信息管理管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="业主名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="tel">
        <el-input type="text" size="mini" v-model="queryModel.tel"></el-input>
      </el-form-item>
      <el-form-item label="所在园区" prop="park">
        <el-input type="text" size="mini" v-model="queryModel.park"></el-input>
      </el-form-item>
      <el-form-item label="所在楼栋" prop="park">
        <el-input type="text" size="mini" v-model="queryModel.building"></el-input>
      </el-form-item>
      <pre></pre>
      <el-form-item label="入住时间" prop="checkinTime">
        <el-date-picker
          v-model="queryModel.checkinTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
      >新增业主信息</el-button>
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" disabled>批量导入</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        @click="handleBatchDelete"
        disabled
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" sort-by="name_" label="业主名称" width="120"></el-table-column>
      <el-table-column prop="tel" sort-by="tel_" label="手机号码" width="120"></el-table-column>
      <el-table-column prop="park" sort-by="park_" label="所在园区" width="120"></el-table-column>
      <el-table-column prop="building" sort-by="building" label="楼栋/单元/房号" width="180"></el-table-column>
      <el-table-column prop="area" sort-by="area_" label="面积" width="100"></el-table-column>
      <el-table-column prop="checkinTime" sort-by="checkin_time" label="入住时间" width="120"></el-table-column>
      <el-table-column prop="propertyCosts" sort-by="property_costs" label="应缴物业费" width="120"></el-table-column>
      <el-table-column prop="cardNo" sort-by="card_no" label="卡号" width="120"></el-table-column>
      <el-table-column prop="authority" sort-by="authority_" label="权限" width="120"></el-table-column>
      <el-table-column prop="carNo" sort-by="car_no" label="车牌" width="120"></el-table-column>
      <el-table-column
        prop="isAccessControl"
        sort-by="is_access_control"
        :formatter="formatBoolean"
        label="是否关联门禁"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="isThePublic"
        sort-by="is_the_public"
        :formatter="formatBoolean"
        label="是否关联公众号"
        width="120"
      ></el-table-column>
      <el-table-column label="操作" width="480">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">修改</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
          <el-button size="mini" type="success" disabled>已关联门禁</el-button>
          <el-button size="mini" type="success" disabled>已关联公众号</el-button>
          <el-button size="mini" type="success" disabled>已关联设备</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <ownerInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></ownerInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import OwnerInfoDetail from "./ownerInfo-detail";
import ownerInfoApi from "@/api/base/ownerInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
        name: "",
        tel: "",
        park: "",
        building: "",
        area: "",
        checkinTime: "",
        propertyCosts: "",
        cardNo: "",
        authority: "",
        carNo: "",
        isAccessControl: "",
        isThePublic: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("name", self.queryModel.name);
      formData.append("tel", self.queryModel.tel);
      formData.append("park", self.queryModel.park);
      formData.append("building", self.queryModel.building);
      formData.append("area", self.queryModel.area);
      formData.append("checkinTime", self.queryModel.checkinTime);
      formData.append("propertyCosts", self.queryModel.propertyCosts);
      formData.append("cardNo", self.queryModel.cardNo);
      formData.append("authority", self.queryModel.authority);
      formData.append("carNo", self.queryModel.carNo);
      formData.append("isAccessControl", self.queryModel.isAccessControl);
      formData.append("isThePublic", self.queryModel.isThePublic);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      ownerInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    formatBoolean: function(row, column, cellValue) {
      var ret = "";
      if (cellValue) {
        ret = "已关联";
      } else {
        ret = "未关联";
      }
      return ret;
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          ownerInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        ownerInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "ownerInfo-detail": OwnerInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>